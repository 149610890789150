.feedback-create-form .buttons-group-wrapper button.btn.standard {
  margin: 0 10px;
}

.feedback-create-form .reports {
  background: #ffffff;
}

.feedback-create-form .reports .feedback-preview-item {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}
