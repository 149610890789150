
@font-face{
    font-family:'Effra Bold';
    src:url(/static/media/EffraBold.c3043539.eot);
    src:url(/static/media/EffraBold.d22f93b9.woff2) format("woff2"),
    url(/static/media/EffraBold.18be1081.woff) format("woff"),
    url(/static/media/EffraBold.ba2d1272.ttf) format("truetype");
}

@font-face{
    font-family:'Effra';
    src:url(/static/media/Effra.c0eb7b3d.eot);
    src:url(/static/media/Effra.76a476ee.woff2) format("woff2"),
    url(/static/media/Effra.62a523e0.woff) format("woff"),
    url(/static/media/Effra.76543dd2.ttf) format("truetype");
}

a.without-underline {
    text-decoration: none;
}


body {
    margin: 0px;
    font-family: "Effra";
}

body {
    overflow-x: hidden;
}

p {
    line-height: 120%;
}
b, strong {
    font-weight: bolder;
    color: black;
}

.cheer-up img {
    max-width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none!important;
}

.compare-buttons button.btn {
    margin: 0px 5px;
}
.compare-buttons {
    text-align: center;
}

.todo .sub-accordion-item {
    margin-bottom: 0px;
    border-bottom: 1px solid #dad4d4;
    padding: 10px 0px;
}

.compare-buttons button.btn{
    background: transparent;
    color: #a7a9ac;
    border: 1px solid #a7a9ac;
}

.compare-buttons button.btn.active{
    background: #a7a9ac;
    color: white;
    border: none;
}

.legend {
    margin: 10px 0px;
}
.legend ul {
    text-align: center;
}
.legend li {
    display: inline-block;
    margin: 0px 10px;
}

.legend li:before {
    font-size: 18px;
}

.legend li.benchmark-legend:before {
    color: black;
}

.legend li.total-legend:before {
    color: #a7a9ac;
}

p strong {
    color: black;
    font-weight: bold;
}

a, a:hover {
    color: #e4212c;
    line-height: 120%;
}

.h3, h3 {
    font-size: 20px;
    font-family: Effra Bold;
}

main {
    padding: 50px 0px;
}

main {
    background: url(/static/media/p4g-desktop-background.bfeeb9f1.png);
    background-size: 100% 100%;
    background-position: top;
    background-repeat: no-repeat;
    min-height: calc(100vh - 135px);
}

main.consent .container {
    max-width: 600px;
}

main .container {
    max-width: 800px;
}

main.survey .container {
    max-width: 600px;
}
.content-title .col-2 {
    max-width: 90px;
}

.settings .content-title.my-auto {
    padding: 10px;
}

.settings .container {
    max-width: 600px;
}

.settings-lable {
    font-family: Effra Bold;
}

.settings-group {
    margin-bottom: 20px;
}

body.reports nav {
    display: none;
}
.reports main {
    background: url(/static/media/reports-background.cb445039.jpg);
    background-size: 100% 100%;
    min-height: 100vh;
    background-attachment: fixed;
}

.row.heading {
    margin: 0px -10px;
}

.fiter-box {
    background: #e8e8e8;
    padding: 10px 15px;
}

.fiter-box ul {
    margin-bottom: 0px;
}

.report-box {
    background: white;
    padding: 10px;
    box-shadow: 0px 0px 1px #b3abab;
    border: 1px solid #c5c5c5;
}

.sub-acordion-title {
    margin-bottom: 10px;
}

.form-group label {
    font-family: Effra Bold;
    color: black;
}
.btn.standard:hover, .btn.standard.gray:hover {
    color: white;
    opacity: .9;
}
.report-accordion.feed-back-list .report-box-title {
    padding-right: 30px;
}

.has-i-disagree .card-header {
    background-image: url(/static/media/i-disagree.fe17814f.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 99% 20%;
}

.has-i-strongly-agree .card-header {
    background-image: url(/static/media/i-strongly-agree.86b5a946.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 99% 20%;
}

.has-i-agree .card-header {
    background-image: url(/static/media/i-agree.cd50ecc4.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 99% 20%;
}

.customer-list {
    background-color: whitesmoke;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #bbbbbb;
    line-height: 120%;
}

.compare-filters .form-group {
    margin-top: 0px;
    margin-bottom: 0px;
}

.customer-list ul {
    margin-bottom: 0px;
}

.has-i-partly-agree .card-header {
    background-image: url(/static/media/i-partly-agree.57905fb2.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 99% 20%;
}

.has-i-strongly-disagree .card-header {
    background-image: url(/static/media/i-strongly-disagree.a01121d1.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 99% 20%;
}
.compare-filter-title {
    min-height: 24px;
}

.compare-buttons button.btn:hover {
    opacity: .9;
}

.compare-buttons button.btn.standard:not(.active):hover {
    color: #a7a9ac;
}

.accordion a.btn.standard:hover {
    color: white;
}

.progress {
    border-radius: 0px;
}

.progress-bar-box div {
    display: inline-block;
}

.row.progress-bar-box {
    margin: 0px;
}
.row.progress-bar-box .col {
    margin: 0px;
    padding: 0px;
}

.progress-bar.benchmark {
    background-color: #f3c615;
    float: left;
}

.progress-bar.favorability {
    background-color: #3cb54a;
    float: left;
}

.progres-bar-label {
    font-size: 14px;
    vertical-align: middle;
}
.stacked-bar span {
    display: inline-block;
    float: left;
    color: white;
    font-size: 14px;
    padding: 1px 0px;
    overflow: hidden;
}

.reciving span.box-title {
    color: #e4212c;
}

.giving span.box-title {
    color:#f3c615;
}

.team span.box-title {
    color:#63a9a4;
}


.report-box-title {
    margin-bottom: 10px;
    line-height: 120%;
    font-family: Effra Bold;
}



.report-close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.report-close i {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 5px;
    color: #dc1f26;
}

.report-close a {
    color: black;
    text-decoration: none;
}

.self-icon {
    width: 1px;
    height: 1px;
    position: absolute;
    top: -7px;
}

.self-icon img {
    width: 20px;
    margin-left: -20px;
}

button.btn.show-comments i {
    color: #e4212c;
    font-size: 12px;
    vertical-align: middle;
    margin-left: 5px;
}

.row.heading {
    font-size: 18px;
}


.report-accordion .card-body {
    border: 0px;
    padding: 0px;
    font-size: 14px;
}

.progres-question {
    margin-bottom: 20px;
}

.progres-question .progres, .progres-question .progress-bar{
    height: 4px;
}

.progres-question .progress-bar {
    background-color: #3cb54a;
}

.progres-question .progres {
    background-color: #e2e2e2;
}

.report-accordion .card-header {
    padding: 10px;
}

.report-accordion .overall-results {
    padding: 0px 10px;
}

.report-accordion .self-information, .report-accordion .progres-question, .comment-title{
    padding: 0px 10px;
}

.comment-box {
    padding: 10px;
}

.comment-box:nth-child(even) {
    background: whitesmoke;
}

.comment-section hr {
    margin-bottom: 0px;
}

.report-accordion .card {
    border-radius: 0px;
    background: white;
    padding: 0px;
    box-shadow: 0px 0px 1px #b3abab;
    border: 1px solid #c5c5c5;
    margin-top: 15px;
}

button.btn.show-comments {
    background: transparent;
    font-size: 14px;
    padding: 0px 10px 10px 10px;
}

.report-accordion .report-box-title {
    margin-bottom: 0px;
    font-family: Effra Bold;
}

hr {
    margin: 10px 0px;
}

.report-accordion.feed-back-list hr {
    margin: 5px 0px;
}

.overall-results {
    position: relative;
}

.stacked-bar {
    width: 100%;
    display: inline-block;
    text-align: center;
}

span.bar-1 {
    background: #881719;
}

span.bar-2 {
    background: #e4212c;
}

span.bar-3 {
    background: #c8c7c7;
}

span.bar-4 {
    background: #8dc640;
}

span.bar-5 {
    background: #3cb54a;
}

.progress-bar-box .progress {
    width: 30px;
    margin-left: 5px;
    vertical-align: middle;
}

.progress-bar.providers {
    background-color: #63a9a4;
}

.report-title {
    line-height: 120%;
}
.self-information {
    margin-top: 5px;
}


.pop-up-filter button.btn.standard {
    margin-top: 50px;
}


nav.navbar .container {
    max-width: 100%;
}

.container {
    max-width: 1210px;
}

ul.main-menu li {
    display: inline-block;
    margin-right: 25px;

}

li.nav-item a{
    font-family: Effra Bold;
}

li.nav-item .dropdown-menu a {
    font-family: Effra;
}
.pop-up-filter  {
    display: block;
    position: fixed;
    top: 30%;
    left: 50%;
    width: 460px;
    height: auto;
    background: url(/static/media/mobile-menu.bf71b475.jpg);
    z-index: 999;
    background-size: 100%;
    background-size: 100% 100%;
    padding: 10px 20px;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.accordion a.btn.standard {
    float: right;
}

.pop-up-filter-wrapper {
    display:none;
}

.pop-up-filter-wrapper.open {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display:block;
}

span.close-filter i {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 5px;
    color: #dc1f26;
}

span.close-filter {
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 9999;
    font-size: 14px;
}

span.close-menu, span.close-filter {
    cursor: pointer;
}

ul.main-menu {
    padding: 10px 0px;
    margin-bottom: 0px;
}

.mobile-header {
    display: none;
}

.mobile-menu {
    display: none;
}

.pop-up-filter-content h3 {
    border-bottom: 1px solid #afafaf;
    padding: 10px 0px;
}

nav .dropdown-toggle::after {
    display:none;
}

ul.main-menu a {
    color: black;
}

nav .dropdown:hover>.dropdown-menu {
    display: block;
    margin-top: -1px;
}

.form-group .btn.dropdown-toggle {
    color: #a7a9ac;
    border-radius: 0px;
    border-color: #bbbbbb;
    font-size: 14px;
    line-height: 120%;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    background: white;
    text-align: left;
}

.chart canvas {
    display: block;
    margin: 20px auto;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: auto;
    vertical-align: .255em;
    content: "";
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-bottom: 0;
    border-left: .4em solid transparent;
    margin-top: 7px;
    float: right;
}

.form-group .dropdown-menu {
    margin: 0px 0px;
    padding: 0px 0px!important;
    background: #f9f9f9!important;
}
.form-group .dropdown-menu a {
    border-bottom: 1px solid #cecece;
    line-height: 100%;
    padding: 20px 20px;
    cursor: pointer;
    text-wrap: wrap;
    font-size: 14px;
}

.form-group .dropdown-menu a:hover {
    color: white;
    background: #e4212c;
}

.form-group .dropdown-menu a:active {
    color: white;
    background: #e4212c;
}

.form-group  .dropdown-menu {
    width: 100%;
}

.reciving img {
    background: #e4212c;
    margin-right: 5px;
    padding: 4px 7px;
    line-height: 100%;
}

.content-title .row {
    height: 48px;
}

.content-title span {
    margin: 0px;
    padding: 0px;
    font-size:20px;
    display: block;
    line-height: 120%;
}

.col.my-auto {
    padding: 0px 10px;
}


.content-title .row.my-auto {
    height: 48px;
}

span.box-subtitle {
    text-align: right;
    padding-right: 15px;
}

.carousel-control-prev {
    left: -23px;
    opacity: 1;
    filter: invert(100%);
    width: 65px;
}

.carousel-control-next {
    right: -23px;
    opacity: 1;
    filter: invert(100%);
    width: 65px;
}

.carousel-item strong {
    color: white;
}

.survey .col.my-auto {
    padding: 5px 15px;
}

.survey .content-box {
    padding: 15px;
}

main.homepage .sub-acordion-title {
    margin-bottom: 0px;
}

main.homepage .sub-acordion-date {
    margin-top: 10px;
}

.select-feedback-provider-section .form-group {
    margin-top: 0px;
}

main.survey .container {
    color: #828788;
}

.survey .content-title .row {
    margin: 0px;
}

.question-box {
    background-color: whitesmoke;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #bbbbbb;
    line-height: 120%;
}

.question-box.has-rating {
    background-image: url(/static/media/has-rating.22fd6705.svg);
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: 99% 99%;
    padding-bottom: 15px;
}

.question-list {
    margin-bottom: 20px;
}

.send-feedback-to span {
    color: #e4212c;
}

.send-feedback-to {
    line-height: 120%;
    margin-bottom: 20px;
}

.carousel-item.red {
    background: #dc1f26;
    color: white;
}

.carousel-item.green {
    background: #63a9a4;
    color: white;
}

.carousel-item.yellow {
    background: #f3c615;
    color: white;
}

.carousel-item.dark-red {
    background: #850608;
    color: white;
}

.carousel-item.black {
    background: black;
    color: white;
}


.carousel-inner {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.carousel-item img {
    width: 100px;
    padding: 10px;
}

.carousel-item p {
    color: white;
    padding: 10px;
    margin-bottom: 0px;
}

.carousel-item .col-2 {
    min-width: 90px;
}

.reciving a.nav-link {
    padding: 0px;
    font-family: Effra Bold;
    color: #e4212c;
}

.carousel {
    position: relative;
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
}


.buttons-group {
    background: white;
    padding: 20px 0px;
    width: 100%;
    text-align: center;
}

.buttons-group-wrapper a.btn.standard {
    margin: 0px 10px;
}

.buttons-group-wrapper {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.compare-buttons .form-group {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    margin-top: 0px;
}

.buttons-group-wrapper a.btn.standard {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

li.nav-item.todo a {
    font-family: Effra Bold;
}

.giving img {
    background: #f3c615;
    margin-right: 5px;
    padding: 4px 7px;
    line-height: 100%;
}

.giving a.nav-link {
    padding: 0px;
    font-family: Effra Bold;
    color: #f3c615;
}

.team img {
    background: #63a9a4;
    margin-right: 5px;
    padding: 4px 7px;
    line-height: 100%;
}

.team a.nav-link {
    padding: 0px;
    font-family: Effra Bold;
    color: #63a9a4;
}

.todo img {
    background: black;
    margin-right: 5px;
    padding: 4px 7px;
    line-height: 100%;
}

.survey .content-title {
    min-height: 40px;
}



.content-title span.box-information {
    font-family: Effra;
    font-size: 14px;
}

.survey span.box-title {
    color: #e4212c;
    line-height: 100%;
}

i.survey-question-icon svg {
    max-width: 30px;
    height: auto;
    margin-right: 5px;
    max-height: 40px;
}


.form-group.text-center a.btn.standard {
    float: none;
}

.col.text-right.my-auto a:nth-child(2), .col.text-right.my-auto a:nth-child(3) {
    margin-top: 10px;
}

.sub-accordion-item .col.text-right.my-auto {
    max-width: 300px;
}


button.btn.survey-question {
    width: 100%;
    text-align: left;
    border-radius: 0px;
    margin-bottom: 10px;
    background: whitesmoke;
    padding: 6px 6px;
    font-size: 14px;
}

button.btn.survey-question.i-strongly-disagree.selected {
    background: rgb(135, 23, 26);
    color: white;
}

button.btn.survey-question.i-strongly-disagree.selected path {
    fill: white;
}

button.btn.survey-question.i-disagree.selected {
    background: rgb(227, 32, 44);
    color: white;
}

button.btn.survey-question.i-disagree.selected path {
    fill: white;
}

button.btn.survey-question.i-partly-agree.selected {
    background: rgb(200, 200, 200);
    color: white;
}

button.btn.survey-question.i-partly-agree.selected path {
    fill: white;
}

button.btn.survey-question.i-agree.selected {
    background: rgb(140, 198, 64);
    color: white;
}

button.btn.survey-question.i-agree.selected path {
    fill: white;
}

button.btn.survey-question.i-strongly-agree.selected {
    background: rgb(59, 180, 74);
    color: white;
}

button.btn.survey-question.i-strongly-agree.selected path {
    fill: white;
}

.comment-section .form-group {
    margin: 5px 0px;
}

.information span {
    display: inline-block;
    margin-right: 10px;
}

.accordion button.btn.btn-link, .accordion button.btn.btn-link:hover {
    font-family: Effra Bold;
    color: black;
    text-decoration: none;
    padding: 0px;
    border: 0px;
    width: 100%;
    text-align: left;
    font-size: 20px;
    border-radius: 0px;
}

.accordion button.btn.btn-link:after {
    content: "\F078";
    color: #333;
    top: 10px;
    right: 12px;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-size: 15px;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}

.accordion button.btn.btn-link[aria-expanded="true"]::after {
    content: "\F077";
    color: #e4212c;

}

.content-title, .content-box {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.card-header {
    background: white;
}

.accordion .card {
    margin-bottom: 20px;
    border-radius: 0px;
    border: 0px;
}

.accordion .card img {
    margin-right: 15px;
    border-radius: 0px;
    padding: 8px 10.1px;
}



.sub-accordion {
    border-top: 1px solid #cbcbcb;
    padding-top: 10px;
}

.card-header {
    padding: 0px;
}

.card-header {
    border-bottom: 0px;
}

.reciving .card-body {
    border-left: 70px solid #e4212c;
}

.giving .card-body {
    border-left: 70px solid #f3c615;
}

.card-body {
    border-left: 70px solid #e4212c;
    padding-top: 0px;
}

.team .card-body {
    border-left: 70px solid #63a9a4;
}

.todo .card-body {
    border-left: 70px solid black;
}

.sub-acordion-title {
    font-family: Effra Bold;
    font-size: 18px;
    line-height: 120%;
}

.sub-accordion-item:last-child {
    margin-bottom: 0px;
}
.sub-accordion-item {
    margin-bottom: 20px;
}

.box-list .sub-accordion-item:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
}

a.btn.standard.no-action strong {
    color: #a7a9ac;
    margin-right: 5px;
}

.form-group {
    margin-top: 20px;
}

.btn.standard, a.btn.standard {
    border: 0px;
    background: #e4212c;
    border-radius: 40px;
    width: 170px;
    font-family: Effra Bold;
    outline: none;
    word-break: keep-all;
    white-space: unset;
    -webkit-hyphens: none;
            hyphens: none;
    line-height: 100%;
    height: 40px;
    font-size: 16px;
    color: white;
    padding:0px;
    margin-left: auto;
    margin-right: auto;
}

a.btn.standard.no-action {
    background: transparent;
    border: 1px solid #a7a9ac;
    color: #a7a9ac;
    pointer-events: none;
}

a.btn.standard {
    display: flex;
    justify-content: center;
    align-items: center;
}

button.btn.standard.add-question {
    background: transparent;
    color: #e4212c;
    width: auto;
}.content-title .row

 .accordion a.btn.standard {
     border: 0px;
     background: #e4212c;
     border-radius: 40px;
     width: 170px;
     font-family: Effra Bold;
     outline: none;
     word-break: keep-all;
     white-space: unset;
     -webkit-hyphens: none;
             hyphens: none;
     line-height: 100%;
     max-width: 170px;
     min-height: 45px;
     font-size: 16px;
     color: white;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 8px 10px;
     float: right;
     line-height: 100%;
 }

.todo .sub-accordion-item {
    margin-bottom: 10px;
}
footer#footer {
    padding: 10px;
    background: #f4c603;
}

footer#footer img {
    width: 180px;
    height: auto;
}

.list .content-box {
    background: white;
    padding: 0px 15px 10px 15px;
}

.content-box {
    background: white;
    padding: 15px;
}
span.circle-notification {
    position: absolute;
    top: -10px;
    right: 13px;
    background: #e4212c;
    border-radius: 50%;
    border: 3px solid white;
    height: 30px;
    width: 30px;
    padding: 3px;
    font-size: 12px;
}

span.number-notification {
    margin-left: 3px;
}

h2 {
    font-size: 24px;
    font-family: Effra Bold;
    margin-bottom: 20px;
}
p {
    color: #838788;
}

.form-control-lg {
    border-radius: 0px;
    border-color: #bbbbbb;
    font-size: 14px;
    line-height: 120%;
    height: 50px;
    padding: 0px 15px;
}

.content-title.reciving {
    background: white;
    font-family: Effra Bold;
}

main ul {
    padding: 0px 0px;
    list-style: none;
}

main ul li::before {
    content: "\25CF";
    color: #e4212c;
    margin-right: 5px;
}

.box-list .sub-accordion-item {
    padding: 10px 0px;
    border-bottom: 1px solid #dad4d4;
    margin-bottom: 0px;
}

button.btn.standard.gray, a.btn.standard.gray {
    background: #a7a9ac;
    outline: none;
}

button.btn.standard.cyan, a.btn.standard.cyan {
    background: #62a9a5;
    outline: none;
}

.fiter-box {
    border-bottom: 1px solid #dad4d4;
    width: 100%;
}

.content-title img {
    margin-bottom: -1px;
    border-radius: 0px;
    padding: 8px 10.1px;
}
.content-title {
    background: white;
    font-family: Effra Bold;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 0px;
}

@media screen and (max-width: 1132px) {
    ul.main-menu.ml-auto, footer#footer {
        display: none;
    }
    .mobile-header {
        display: block;
    }

    .logo {
        width: 100%;
        text-align: center;
    }

    ul.main-menu a img {
        width: 50px;
    }
    p,a, li, label, div {
        font-size: 14px;
    }
    .logo img {
        width: 200px;
        padding: 5px;
    }
    main {
        background-size: 100% 100%;
    }
    footer#footer {
        display: none;
    }
    .accordion a.btn.standard {
        width: 120px;
        font-size: 12px;
        padding: 5px 0px;
        vertical-align: middle;
        line-height: 100%;
        min-height: 35px;
    }

    a.btn.standard {
        width: 120px;
        font-size: 12px;
        padding: 5px 0px;
        vertical-align: middle;
        line-height: 100%;
        min-height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .sub-acordion-title {
        font-size: 14px;

    }
    .accordion .card img {
        max-width: 50px;
    }
    .accordion .card-body {
        border-left-width: 50px;
    }
    .accordion .card img {
        padding: 7px 5px;
    }
    .sub-acordion-date, .sub-acordion-response {
        font-size: 12px;
        line-height: 120%;
    }

    .sub-acordion-title {
        margin-bottom: 10px;
    }

    .sub-accordion-item .col:first-child {
        padding: 0px 15px;
        margin: 0px;
    }
    .todo .sub-acordion-title {
        padding-top: 0px;
    }
    .todo .sub-accordion-item {
        border-bottom: 1px solid #e4dfdf;
        padding-bottom: 10px;
    }

    .todo .sub-accordion-item:last-child {
        border-bottom: none;
        padding-bottom: 0px;
    }

    .card-body {
        padding: 10px;
    }

    .card-body {
        padding-top: 0px;
    }
    .mobile-menu {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(/static/media/mobile-menu.bf71b475.jpg);
        z-index: 9999;
        background-size: 100%;
        background-size: 100% 100%;
    }
    .mobile-menu ul {
        list-style: none;
        padding: 0px;
    }


    .container {
        padding-right: 10px;
        padding-left: 10px;
    }
    main {
        padding: 20px 0px;
    }
    .mobile-menu {
        padding: 0px 20px;
        display: none;
    }
    ul.main-menu-mobile-menu li a {
        color: black;
    }
    ul.main-menu-mobile-menu li {
        margin-top: 20px;
        font-family: Effra Bold;
    }
    ul.main-menu-mobile-menu li.reciving, li.nav-item.giving, li.nav-item.team, li.nav-item.todo  {
        border-bottom: 1px solid #d4d4d4;
    }

    ul.main-menu-mobile-menu li.reciving a  {
        color:#e4212c;
    }
    li.nav-item.sub-item a {
        color: #838788;
        font-family:Effra;
    }
    li.nav-item.sub-item {
        font-family: Effra;
        padding-left: 65px;
    }
    li.nav-item.giving a {
        color: #f3c615;
    }
    li.nav-item.team a {
        color: #63a9a4;
    }
    li.nav-item.sub-item {
        margin-top: 5px;
    }
    li.nav-item.last {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
    }
    ul.main-menu-mobile-menu img {
        max-width: 50px;
    }
    span.close-menu {
        position: fixed;
        top: 10px;
        right: 15px;
        z-index: 9999;
        font-size: 14px;
    }
    span.close-menu i {
        font-size: 18px;
        vertical-align: middle;
        padding-left: 5px;
        color:#dc1f26;
    }
    .mobile-header {
        background: #f3c615;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        padding: 5px 5px;
        z-index: 9999;
    }
    .mobile-header img {
        width: 150px;
        height: auto;
    }
    nav.navbar {
        margin-top: 40px;
    }
    span.mobile-menu-button {
        position: fixed;
        top: 10px;
        right: 15px;
        z-index: 9999;
        font-size: 14px;
    }
    span.mobile-menu-button i {
        font-size: 18px;
        vertical-align: middle;
        padding-left: 5px;
        color:#dc1f26;
    }
    .mobile-menu.open {
        display: block;
    }
    .mobile-header {
        display: block;
    }
    h2 {
        font-size: 18px;
    }
    .accordion button.btn.btn-link, .accordion button.btn.btn-link:hover {
        font-size: 18px;

    }
    .content-title span {
        font-size: 16px;
    }
    button.btn.standard.gray, a.btn.standard.gray, button.btn.standard, a.btn.standard {
        width: 120px;
        font-size: 12px;
        padding: 5px 0px;
        vertical-align: middle;
        line-height: 100%;
        min-height: 35px;
    }



    .fiter-box .col.text-right {
        padding: 0px 10px;
    }
    .content-title img {
        padding: 7px 5px;
        max-width: 50px;
    }

    .pop-up-filter {
        top: 0px;
        left: 0px;
        transform: translate(0%, 0%);
        width: 100%;
        height: 100%;
    }
    .content-title .row.my-auto {
        height: 40px;
    }
    .content-title {
        display: inline-block;
        width: 100%;
    }
    .content-title .row {
        height: 40px;
        margin: 0px -10px;
    }

    .content-title .col, .content-title .col-2 {
        padding: 0px 10px;
    }

    .carousel-control-prev {
        width: 40px;
    }

    .carousel-control-next {
        width: 40px;
    }



    span.circle-notification {
        font-size: 10px;
        top: -10px;
        right: 15px;
        height: 25px;
        width: 25px;
        padding: 3px;
    }
    .content-title .col-2 {
        max-width: 65px;
    }
    .buttons-group-wrapper a.btn.standard {
        display: inline-flex;
    }
    .buttons-group-wrapper {
        width: 100%;
    }
    .progres-bar-label {
        font-size: 12px;
    }
    .report-close {
        top: 5px;
        right: 10px;
    }
    main.reports {
        padding-top: 40px;
    }
    main.survey .container {
        font-size: 14px;
    }
    .report-accordion.feed-back-list .report-box-title {
        padding-right: 20px;
    }
    .content-title span.box-information {
        font-family: Effra;
        font-size: 12px;
    }
    .col.my-auto, .col.text-right {
        max-width: 50%;
    }

    .content-title .col {
        padding: 0px 5px;
    }

    .survey .content-title .col, .survey .content-title .col.text-right {
        max-width: 100%;
    }
    .compare-filters div, .compare-filters .col {
        font-size: 10px;
        letter-spacing: -0.4px;
        padding: 0px 5px;
    }
    .compare-filter-title {
        min-height: 18px;
    }
    .compare-filters .form-group .btn.dropdown-toggle {
        font-size: 12px;
    }
    .compare-filters .form-group button.btn.dropdown-toggle {
        padding: 0px 5px;
        height: 30px;
        font-size: 10px;
    }
    .compare-filters .form-group button.btn.dropdown-toggle::after {
        margin-top: 5px;
    }
    .compare-filters .dropdown {
        margin: 0px;
        width: 100%;
        padding: 0px;
        margin-top: 5px;
    }
    .compare-filters .form-group {
        margin-top: 0px;
    }
    .compare-filters button.btn.dropdown-toggle span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75px;
        float: left;
    }
    .compare-buttons .form-group {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
    .compare-buttons .form-group button.btn.dropdown-toggle {
        height: 40px;
    }
    .col-3 {
        margin-right: 10px;
    }
}


/**Fixed self star for 1 score on stacked bart for desktop - set left:0% **/
.report-accordion .overall-results {
    padding: 0 0px;
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.self-icon img {
    margin-left: -10px;
}

/**Style for Login Page **/

.login .container {
    max-width: 500px;
}

.sign-in-form .submit-field {
    margin: 40px 0px;
}

/**Style for Choose External Internal Page **/

.buttons-group-wrapper.external-internal button.btn.standard {
    margin: 0px 2px;
}

.buttons-group-wrapper.external-internal {
    width: 100%;
}



/**Rechart stlye**/

.recharts-wrapper, .recharts-wrapper svg.recharts-surface {
    max-width: 100%;
}


@media screen and (max-width: 1132px) {
    .buttons-group-wrapper.external-internal a.btn.standard {
        margin: 10px 0px;
        font-size: 12px;
        width: 80px;
    }
}



@media screen and (max-width: 1132px) {
    /**Fixed self star for 1 score on stacked bart for mobile and tablet  - set left:0% **/
    .report-accordion .overall-results {
        max-width: 96%;
    }
    .content-title span {
        letter-spacing: -0.5px;
    }
    /**Add style for input button for mobile and tablet**/
    input.btn.standard {
        width: 110px;
        font-size: 12px;
        padding: 5px 0px;
        vertical-align: middle;
        line-height: 100%;
        min-height: 35px;
        height: 35px;
    }

}

@media screen and (max-width: 320px) {
    /**Smaler screen style**/
    .content-title span {
        font-size: 13px;
        letter-spacing: -0.5px;
    }
    .accordion button.btn.btn-link, .accordion button.btn.btn-link:hover, h2 {
        font-size: 16px;
    }
    .sub-acordion-title {
        font-size: 12px;
    }
    .accordion a.btn.standard, a.btn.standard, a.btn.standard, a.btn.standard.gray, button.btn.standard, button.btn.standard.gray, .btn.standard, a.btn.standard {
        width: 110px;
        padding: 5px 0px;
        min-height: 30px;
        height: 35px;
    }
}

/**Compare filters mobile font size**/

@media screen and (max-width: 480px) {

    .compare-filters .form-control {
        font-size: 11px;
    }
}


/**Minor styles for IE**/

.accordion button.btn.btn-link, .accordion button.btn.btn-link:hover {
    background-color:white;
}

.col.my-auto {
    align-self: center;
}

.carousel-item.black .text-left {

    align-self: center;
}

@media screen and (max-width: 1132px) {
    .box-list .sub-accordion-item .col.text-right.my-auto {
        max-width: 250px;
    }
}
@media screen and (max-width: 480px) {

    label.visible {
        font-size: 10px;
        letter-spacing: -0.4px;
    }
    .select-field.form-group .form-group {
        padding: 0px;
    }
}


/**Fix text align in carousel boxes in IE**/
.carousel-item .text-left {

    align-self: center;
}

/**Fix carousel arrows in IE**/

.carousel-control-next, .carousel-control-prev {
    filter: invert(0%);
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}


.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

/**Fix dropdown filters**/

@media screen and (max-width: 480px) {
    .compare-filters button.btn.dropdown-toggle {
        display: inline-block;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
    }
}



/**Admin Style**/

.admin .container {
    max-width: 1210px;
    background: white;
    padding: 20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.admin .container .content-title {
    max-width: 1210px;
}

.admin .container.container-full {
    max-width: calc(100% - 2rem);
}

.admin .container.container-full .content-title {
    max-width: 100%;
}

.table th {
    font-weight: 400;
}

.surveys-table thead {
    background: #e4212c;
    color: white;
    border: 0px;
}

tbody tr:nth-child(even) {
    background: whitesmoke;
}

table td, .table th {
    border: 0px;
}

ul.pagination li:before {
    display:none;
}

.page-item.active .page-link {
    border-color: #e4212c;
    background-color: #e4212c;
}

.page-link {
    color: #e4212c;
}

.progress-in-numbers.text-center {
    font-size: 12px;
}

table .btn.standard, table a.btn.standard {
    font-size: 15px;
    display: block;
    height: 30px;
    margin: 5px auto;
    max-width: 100px;
}

.table td, .table th {
    vertical-align: middle;
}

.table-survey-wrapper {
    min-width: 1100px;
}

thead th:last-child {
    text-align: center;
}

.form-group input {
    color: #a7a9ac;
    border-radius: 0px;
    border-color: #bbbbbb;
    font-size: 14px;
    line-height: 120%;
    height: 50px;
    padding: 0px 15px;
    width: 100%;
    background: white;
}

input#datepicker {
    background: transparent;
    border-bottom: 2px solid rgba(0,0,0,.42);
}

.form-group .launch-date, .form-group .end-date  {
    border: 0px;
    background-color: transparent;
    border-bottom: 1px solid #777777;
}

.form-group .reminder {
    display: flex;
    margin: 15px 0;
}

.form-group .reminder i {
    margin: auto 5px;
}

.form-group .reminder p {
    margin: auto 0;
}

.form-group.date {
    position: relative;
}

i.gj-icon {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 24px;
    color:#e4212c;
}

.gj-picker.gj-picker-md.datepicker.gj-unselectable {
    z-index: 9999999;
}

.form-group.date input {
    height: 30px;
    color: black;
}

input#datepicker {
    border: 0px;
}

input#datepicker {
    color: black;
}

.report-accordion .overall-results {
    width: 98%;
}


/**Toltip Style**/

.tooltip strong {
    color: white;
}

.report-box-title i {
    color: #e4212c;
}

.self-text {
    font-size: 11px;
    min-width: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    text-align: center;
}

.stacked-bar {
    margin-bottom: 10px;
}

.reports .card-body i {
    color: #e4212c;
}

.row.heading p {
    font-size: 14px;
}


/**Secondary Button Darker Color*/

button.btn.standard.gray, a.btn.standard.gray {
    background: #4e4e4e;
    outline: none;
}

.compare-buttons button.btn.active {
    color: white;
    border: none;
    background: #4e4e4e;
}

/**Add suport in meni and in accordion**/
.support img {
    background: #5d5d5d;
    margin-right: 5px;
    padding: 4px 7px;
    line-height: 100%;
}

.support .card-body {
    border-left: 70px solid #5d5d5d;
}

ul.main-menu.ml-auto li {
    font-size: 12px;
}

ul.main-menu img {
    margin-top: -4px;
}


@media screen and (max-width: 1132px) {
    ul.main-menu-mobile-menu li.reciving, li.nav-item.giving, li.nav-item.team, li.nav-item.todo, li.nav-item.support {
        border-bottom: 1px solid #d4d4d4;
    }
    .accordion .card-body {
        border-left-width: 50px;
    }
    ul.main-menu.ml-auto li {
        font-size: 11px;
    }
}

/**Smaller Icons - Bigger fonts**/

ul.main-menu img {
    margin-top: -4px;
    max-width: 48px;
}

@media screen and (max-width: 1300px) {
    ul.main-menu.ml-auto li {
        font-size: 12px;
    }
}

@media screen and (max-width: 1400px) {
    ul.main-menu.ml-auto li {
        font-size: 14px;
    }
}

@media screen and (min-width: 1400px) {
    ul.main-menu.ml-auto li {
        font-size: 16px;
    }
}
@media screen and (max-width: 1132px) {
    .mobile-menu.open {
        overflow-y: scroll;
    }
}

main {
    background-attachment: fixed;
}

/**Fix admin popup**/

div.admin-filters div.pop-up-filter-wrapper div.pop-up-filter {
    top: 50%;
}

@media screen and (max-width: 1132px) {
    div.admin-filters div.pop-up-filter-wrapper div.pop-up-filter {
        top: 0;
        overflow-y: scroll;
    }
}

/**External Customer List design**/

.external-providers {
    border-bottom: 1px solid #d0d0d0;
    position: relative;
}

.external-providers .add-provider {
    margin: 20px 0px 20px 0px;
}

.external-providers .close {
    position: absolute;
    right: 5px;
    top: -15px;
    z-index: 99;
}

.external-providers label {
    font-size: 12px;
}

.external-providers .question-box {
    padding: 20px 10px 0px 10px;
    background-color: white;
    border: 0px;
    border-top: 1px solid #e8e8e8;
}

.external-providers .form-group input, .external-providers .form-group .btn.dropdown-toggle {
    height: 40px;
}


@media screen and (max-width: 1132px) {
    .external-providers .question-box .col-5, .external-providers .question-box .col-4, .external-providers .question-box .col-3 {
        max-width: 100%!important;
        flex: 100% 1!important;
    }

    .external-providers .question-box .col-3 {
        margin-right: 0px;
    }

    div.update-survey-form div.external-providers .col-4 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/**Visibility and Anonymity**/
.visibility-anonimity {
    line-height: 120%;
    border: 1px solid #ececec;
    margin: 20px 10px;
    text-align: left;
    padding: 15px;
}
.visibility-anonimity img {
    max-height: 60px;
    margin: 0px auto;
    display: block;
    width: auto;
}

.visibility-anonimity .row>.col.my-auto {
    padding: 0px 15px;
}

.visibility-anonimity p {
    margin-bottom: 0px;
}

.visibility-anonimity>.col.my-auto {
    padding: 0px 0px;
}

.visibility-anonimity>.col.my-auto>.row {
    margin: 0px 0px;
}

@media screen and (max-width: 700px) {

    .visibility-anonimity img {
        float: none;
        display: block;
        margin: 0px auto;
    }
    .visibility-anonimity {
        text-align: center;
    }
}

/**Fix button text overlapping**/

a.btn.standard {
    padding: 0px 10px;
}

.btn.standard, a.btn.standard {
    min-height: 40px;
    height: auto;
}



/**Style for menu word break**/
ul.main-menu li a {
    max-width: 200px;
    display: inline-flex;
    white-space: normal;
    margin-top: auto;
    margin-bottom: auto;
}
ul.main-menu li a * {
    display: flex;
    margin: auto;
}

ul.main-menu {
    display: flex;
}

ul.main-menu li span {
    margin-left: 10px;
}

.support .nav-link {
    padding: 0px;
}

ul.main-menu li {
    display: flex;
}


/**Add fallback font for Cyrillic **/
.content-title {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

.settings-lable {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

body {
    margin: 0;
    font-family: Effra, Tahoma, Sans-Serif;
    overflow-x: hidden;
}

.top-bar #navbarDropdown {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

.btn.standard, a.btn.standard {
    font-family: Effra, Tahoma, Sans-Serif;
}

li.nav-item .dropdown-menu a {
    font-family: Effra, Tahoma, Sans-Serif;
}

li.nav-item.todo a {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

li.nav-item a {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

.accordion button.btn.btn-link, .accordion button.btn.btn-link:hover {
    font-family: Effra Bold, Tahoma, Sans-Serif;

}

.sub-acordion-title {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

.content-title.reciving {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

.h3, h3 {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}


/**Enable scroll on Admin Add Survey Popup**/

@media screen and (min-width: 1024px) {

    .pop-up-filter-wrapper.open {
        overflow: scroll;
    }


    div.admin-filters div.pop-up-filter-wrapper div.pop-up-filter {
        margin: 100px 0px;
        position: absolute;
    }}


/**Fix scrolling in dropdown menu in IE**/
.form-group .form-group .dropdown-menu.show {
    width: 100% !important;
    -webkit-transform: translate3d(0px, 50px, 0px);
    top: 0px !important;
    left: 0px !important;
    transform: translate3d(0px, 50px, 0px) !important;
}

/**Kore AI style**/
.kore-chat-window ul.chat-container li.fromOtherUsers .messageBubble, .kore-chat-window .buttonTmplContent .buttonTmplContentHeading {
    background-color: #e4212c;
}

.kore-chat-window .buttonTmplContent .buttonTmplContentChild {
    color: #63a9a4;
    border-radius: 20px;
    margin: 5px 0px!important;
    border: 1px solid #63a9a4;
}

.kore-chat-window {
    max-height: 530px;
    background:white;
}

.kore-chat-window * {
    color: white;
}

.kore-chat-window ul.chat-container li.fromCurrentUser .messageBubble {
    background-color: #63a9a4;
}

.kore-chat-window .kore-chat-footer .chatSendMsg {
    color: #63a9a4;
}

.kore-chat-window ul.chat-container li.fromOtherUsers .messageBubble, .kore-chat-window .buttonTmplContent .buttonTmplContentHeading {
    margin-bottom: 10px!important;
}

.carousel {
    width: 100%;
}

.tips .carousel {
    margin: 0 auto 0 auto;
    overflow: unset;
}

.kore-chat-window.minimize .minimized-title {
    background: #e4212c;
}

.kore-chat-window .kore-chat-header {
    background-color: red;
    border: none!important;
}
.kore-chat-window .kore-chat-header {
    background-color: #eaeaea;
    border: none!important;
    color: black;
}

div#botHeaderTitle {
    color: black;
}

.kore-chat-window .kore-chat-footer .chatInputBox {
    color: black;
}

.kore-chat-window .sendBtnCnt .sendButton {
    background: #e4212c;
    padding: 5px;
    border: 1px solid #e4212c;
}

.kore-chat-window .kore-chat-header .chat-box-controls button {
    height: 40px;
    line-height: 40px;
}
.kore-chat-window .kore-chat-header .chat-box-controls button.reload-btn {
    line-height: 45px;
}

@media (max-width: 767px) {
    .kore-chat-window {
        bottom: 10px!important;
        top: auto!important;
        height: 100%!important;
    }
    .kore-chat-window {
        max-height: 450px;
    }
}

.kore-chat-window span.quickreplyText {
    color: #009dab;
}

.kore-chat-window span.quickreplyText:hover {
    color: white;
}

.kore-chat-window .kore-chat-footer .chatInputBox > * {
    color: black;
}

.kore-chat-window .purejscarousel-btn {
    border:none !important;
}

.kore-chat-window .carouselTitleBox .carouselButton {
    border: 1px solid #e2e2e2!important;
}

/** Arabic style **/
.accordion button.btn.btn-link:lang(ar-EG), .accordion button.btn.btn-link:hover:lang(ar-EG) {
    direction: ltr;
    text-align: right;
    padding-right: 40px;
    display: inline-block;
    width: 100%;
}
.home-page .feedback-provide-form button.btn.standard:lang(ar-EG) {
    float: none;
}
main.profile-page.settings:lang(ar-EG) {
    text-align: right;
}
.content-box textarea:lang(ar-EG) {
    text-align: right;
    direction: ltr;
}
span.circle-notification:lang(ar-EG)  {
    position: relative;
}
.report-accordion.feed-back-list .report-box-title:lang(ar-EG) {
    padding-right: 20px;
    direction: ltr;
}
.buttons-group-wrapper:lang(ar-EG) {
    text-align: right;
}
.form-group .dropdown-menu a:lang(ar-EG) {
    text-align: right;
}
.col.text-center *:lang(ar-EG), .col.text-center:lang(ar-EG) {
    text-align: right!important;
}
.content-box *:lang(ar-EG) {
    text-align: right;
}
button.btn.show-comments:lang(ar-EG) {
    text-align: right;
}
h2:lang(ar-EG)  {
    text-align: right;
}
.information:lang(ar-EG)  {
    text-align: right;
}
button.btn.survey-question:lang(ar-EG) {
    text-align: right;
}
ul.filter-selected:lang(ar-EG) {
    text-align: right;
}
div.input-field-component>label.visible:lang(ar-EG), div.input-field-component small.input-field__error:lang(ar-EG) {
    text-align: right;
}
#collapseOne:lang(ar-EG) > div > div > div:nth-child(3) > div > div.col.text-right {
    text-align: left!important;
}
.home-page .accordion a.btn.standard:lang(ar-EG), .home-page button.btn.standard:lang(ar-EG) {
    float: left!important;
    margin-right: auto;
    text-align: center;
    margin-left: 0px;
}
.report-box-title:lang(ar-EG) {
    text-align: right;
}
main.report-details.reports:lang(ar-EG) {
    text-align: right;
}
.report-title:lang(ar-EG) {
    text-align: right;
}
.survey .content-box:lang(ar-EG) {
    text-align: right;
}
.survey span.box-title:lang(ar-EG)  {
    text-align: right;
}
.compare-filter-title:lang(ar-EG)  {
    text-align: right;
}
.comment-box:lang(ar-EG) {
    text-align: right;
}
input.btn.standard:lang(ar-EG) {
    text-align: center;
}
button.btn.standard:lang(ar-EG) {
    text-align: center!important;
}
.submit-field:lang(ar-EG) {
    text-align: right;
}
main.profile-page.settings:lang(ar-EG) a.btn.standard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
:lang(ar-EG) *, :lang(ar-EG)  *:placeholder{
    text-align: right;
}
.form-group .btn.dropdown-toggle:lang(ar-EG) {
    text-align: right;
}
:lang(ar-EG)  .sub-accordion-item * {
    text-align: right;
}
:lang(ar-EG)  .user-survey-filters {
    text-align: right;
}
.accordion button.btn.btn-link img:lang(ar-EG), .accordion button.btn.btn-link:hover img:lang(ar-EG) {
    margin-right: auto;
    text-align: left;
    float: left;
}
:lang(ar-EG) .sub-accordion-item span.circle-notification {
    text-align: center;
}
:lang(ar-EG) .content-title a.without-underline {
    text-align: right;
}
:lang(ar-EG)  .carousel-item p {
    text-align: right;
}
:lang(ar-EG) .sub-accordion a.btn.standard {
    margin-right: 0px;
}
p:lang(ar-EG) {
    text-align: right;
}
:lang(ar-EG) .tips * {
    text-align: right;
}
.content-title img:lang(ar-EG) {
    margin: 0px;
}
ul.main-menu a img:lang(ar-EG) {
    margin-left: 5px;
}
.home-page .sub-accordion .sub-acordion-title:lang(ar-EG)  {
    text-align: right;
}
.home-page .accordion a.btn.standard:lang(ar-EG)  {
    float: left;
}
.home-page  button.btn.standard:lang(ar-EG)  {
    float: left;
}
@media screen and (max-width: 1132px) {
    ul.main-menu-mobile-menu:lang(ar-EG)  {
        text-align: right;
    }
    span.close-menu:lang(ar-EG)  {
        right: auto;
        left: 10px;
    }
}
@media screen and (min-width: 1032px) {
    body .box-list .sub-accordion-item .col.text-right.my-auto:lang(ar-EG)  {
        max-width: 250px;
    }
    body .col.my-auto:lang(ar-EG) , body .col.text-right:lang(ar-EG)  {
        max-width: initial;
    }
    body main.feedback-list-page .col:last-child:lang(ar-EG), body main.surveys-page.list .col:last-child:lang(ar-EG) {
        max-width: 250px;
    }
    body .home-page .accordion a.btn.standard:lang(ar-EG),body .home-page button.btn.standard:lang(ar-EG) {
        float: none;
    }
}
.content-box:lang(ar-EG) .form-group.text-center {
    display: inline-block;
}
.dropdown-menu:lang(ar-EG)  {
    text-align: right;
}
.sub-acordion-date:lang(ar-EG) {
    text-align: right;
}
body .home-page .accordion a.btn.standard:lang(ar-EG), body .home-page button.btn.standard:lang(ar-EG) {
    float: none!important;
}
.user-survey-filters.fiter-box .col.text-right.my-auto:lang(ar-EG)  {
    text-align: left!important;
}
.notification-item.row .text-right:lang(ar-EG) {
    text-align: left !important;
}
.overall-category-result .report-box-title {
    min-height: 40px;
    text-align: center;
}
.overall-category-result__favorability {
    width: 60px;
    margin: 14px auto 0 auto;
}
.statistic-page, .statistic-team-page {
    padding: 50px 0 150px 0;
}

div.input-field-component > label {
  display: none;
}

div.input-field-component > label.visible {
  display: block;
}

div.input-field-component small.input-field__error {
  display: block;
}

div.select-field > label {
  display: none;
  font-family: Effra, Tahoma, Sans-Serif;
}

div.select-field > label.visible {
  display: block;
}

div.select-field small.select-field__error {
  display: block;
}

.form-group--business-unit .form-group > label {
    font-family: Effra Bold, Tahoma, Sans-Serif;
}

.form-group--business-unit .form-group {
    margin-top: 0;
    margin-bottom: 0;
}

.select-feedback-provider-section--pod_feedback {
    margin-top: 20px;
}

.add-survey .buttons-group-wrapper button.btn.standard {
  margin: 0 10px;
}

.admin-top-bar .mobile-menu-button {
  cursor: pointer;
}

.admin-create-survey-form .quarter div.form-group {
  margin-top: 0;
}

.admin-create-survey-form button.btn.standard {
  margin-top: 0;
  margin-right: 10px;
}

.admin-create-survey-form .form-group {
  margin-bottom: 0;
}
div.react-bootstrap-table-pagination > div.col-md-6:first-child {
  display: none;
}

ul.react-bootstrap-table-page-btns-ul {
  float: unset;
}

div.admin-survey-table th.sort.asc:after {
  display: none;
}

div.admin-survey-table th.sort.desc:before {
  display: none;
}

div.admin-survey-table span.caret-4-asc {
  display: none;
}

div.admin-survey-table span.caret-4-desc {
  display: none;
}

div.admin-survey-table span.order-4 {
  display: none;
}

th.sort:before,
th.sort:after {
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  border: 5px solid transparent;
  width: 0;
  height: 0;
  content: "";
}
th.sort:before {
  margin-top: -10px;
  border-bottom-color: #fff;
}
th.sort:after {
  margin-top: 3px;
  border-top-color: #fff;
}

th.sort {
  position: relative;
}

div.file-field small.file-field__error {
  display: block;
}

.download-csv-download-link {
  background: none;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: #e4212c;
}

.admin-survey-exclude-rule-table-stats {
  margin: 16px 0;
}

.admin-survey-exclude-rule-table-stats__note {
  margin-left: 12px;
  color: #707070;
  font-size: 14px;
}

.top-bar #navbarDropdown {
  font-family: Effra Bold, Tahoma, Sans-Serif;
  cursor: pointer;
}

.top-bar .mobile-menu-button {
  cursor: pointer;
}

.top-bar li.reciving > span {
  color: #e4212c;
}

.top-bar li.giving > span {
  color: #f3c615;
}

.top-bar li.team > span {
  color: #63a9a4;
}

div.notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1060;
}

div.notification.hidden {
  width: 0;
  height: 0;
}

.cant-provide-form .buttons-group-wrapper button.btn.standard {
  margin: 0 10px;
}
.notification-item button.cant-provide {
  margin-top: 10px;
  background: transparent;
  border: 1px solid #a7a9ac;
  color: #a7a9ac;
}

.notification-item button.cant-provide:hover {
  color: #a7a9ac;
}
div.update-survey-form div.external-providers div.form-group {
  margin-top: 0;
}

div.update-survey-form div.external-providers div.form-group input {
  padding: 0 8px;
}

div.update-survey-form div.external-providers div.form-group .btn.dropdown-toggle {
  padding: 0 8px;
}


div.update-survey-form .autocomplete-select-field-component label {
  font-family: Effra, Tahoma, Sans-Serif;
}

div.update-survey-form .autocomplete-select-field-component label p {
  margin: 0;
}

div.update-survey-form div.external-providers .col-4 {
  padding-left: 0;
  padding-right: 0;
}

div.update-survey-form div.external-providers .title {
  margin: 1rem 0;
  color: black;
  font-family: Effra Bold;
}

.update-survey-form__peer__providers {
  display: none;
}

.update-survey-form__peer__providers--visible {
  display: block;
}

.update-survey-form__peer__custom-providers .autocomplete-select-field-component {
  margin-bottom: 0;
}

.update-survey-form__peer__custom-providers .creatable-select-field {
  display: none;
}

.update-survey-form__peer__custom-providers--visible .creatable-select-field {
  display: block;
}

.update-survey-form__button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  margin: 18px 0;
  padding: 0;
  text-align: left;
  color: #838788;
}

.update-survey-form__button::before {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #e4212c;
  border-radius: 50%;
  margin-right: 8px;
  padding: 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  content: '+';
}

.update-survey-form__button--add-question {
  border: 1px solid #bbbbbb;
  padding: 10px;
  margin: 10px 0 20px 0;
  width: 100%;
}

.update-survey-form__button--add-peer {
  background: #e4212c;
  margin: 8px 0;
  padding: 8px;
  color: #ffffff;
}

.update-survey-form__button--add-peer::before {
  background: #ffffff;
  color: #e4212c;
}

.update-survey--peers ol {
  list-style-position: inside;
  padding: 0;
}

.feedback-provide-form .buttons-group-wrapper button.btn.standard {
  margin: 0 10px;
}

.feedback-provide-form .form-check-input {
  position: relative;
  margin: 0 0.25rem 0 0;
}

.feedback-provide-form .reports {
  background: #ffffff;
}

.feedback-provide-form .reports .feedback-preview-item {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.surveys-page .box-list .sub-accordion-item:last-child {
  padding: 10px 0;
  border-bottom: 1px solid #dad4d4;
}

.surveys-team-page .box-list .sub-accordion-item:last-child {
  padding: 10px 0;
  border-bottom: 1px solid #dad4d4;
}

@media print {
  .report-details * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .reports main.report-details {
    background: none;
  }

  .report-details .collapse:not(.show) {
    display: block;
  }

  .report-details .report-close {
    display: none;
  }

  .report-details .report-export {
    display: none;
  }

  .report-details .report-box {
    background: whitesmoke;
    border: none;
    box-shadow: none;
  }

  .report-details .card {
    border: none;
    box-shadow: none;
  }

  .report-details .show-comments {
    display: none;
  }

  #footer {
    display: none;
  }
}

.manual-reminder-form .buttons-group-wrapper button.btn.standard {
  margin: 0 10px;
}

.feedback-list-page .box-list .sub-accordion-item:last-child {
  padding: 10px 0;
  border-bottom: 1px solid #dad4d4;
}

.feedback-create-form .buttons-group-wrapper button.btn.standard {
  margin: 0 10px;
}

.feedback-create-form .reports {
  background: #ffffff;
}

.feedback-create-form .reports .feedback-preview-item {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

@media print {
  .feedback-preview * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .reports main.feedback-preview {
    background: none;
  }

  .feedback-preview .collapse:not(.show) {
    display: block;
  }

  .feedback-preview .report-close {
    display: none;
  }

  .feedback-preview .report-export {
    display: none;
  }

  .feedback-preview .report-box {
    background: whitesmoke;
    border: none;
    box-shadow: none;
  }

  .feedback-preview .card {
    border: none;
    box-shadow: none;
  }

  .feedback-preview .card-header {
    background: none;
  }

  .feedback-preview .show-comments {
    display: none;
  }

  #footer {
    display: none;
  }
}

