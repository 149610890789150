div.input-field-component > label {
  display: none;
}

div.input-field-component > label.visible {
  display: block;
}

div.input-field-component small.input-field__error {
  display: block;
}
