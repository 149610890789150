div.select-field > label {
  display: none;
  font-family: Effra, Tahoma, Sans-Serif;
}

div.select-field > label.visible {
  display: block;
}

div.select-field small.select-field__error {
  display: block;
}
