div.notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1060;
}

div.notification.hidden {
  width: 0;
  height: 0;
}
