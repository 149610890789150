.admin-create-survey-form .quarter div.form-group {
  margin-top: 0;
}

.admin-create-survey-form button.btn.standard {
  margin-top: 0;
  margin-right: 10px;
}

.admin-create-survey-form .form-group {
  margin-bottom: 0;
}