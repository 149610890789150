div.admin-survey-table th.sort.asc:after {
  display: none;
}

div.admin-survey-table th.sort.desc:before {
  display: none;
}

div.admin-survey-table span.caret-4-asc {
  display: none;
}

div.admin-survey-table span.caret-4-desc {
  display: none;
}

div.admin-survey-table span.order-4 {
  display: none;
}

th.sort:before,
th.sort:after {
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  border: 5px solid transparent;
  width: 0;
  height: 0;
  content: "";
}
th.sort:before {
  margin-top: -10px;
  border-bottom-color: #fff;
}
th.sort:after {
  margin-top: 3px;
  border-top-color: #fff;
}

th.sort {
  position: relative;
}
