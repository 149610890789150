div.update-survey-form div.external-providers div.form-group {
  margin-top: 0;
}

div.update-survey-form div.external-providers div.form-group input {
  padding: 0 8px;
}

div.update-survey-form div.external-providers div.form-group .btn.dropdown-toggle {
  padding: 0 8px;
}


div.update-survey-form .autocomplete-select-field-component label {
  font-family: Effra, Tahoma, Sans-Serif;
}

div.update-survey-form .autocomplete-select-field-component label p {
  margin: 0;
}

div.update-survey-form div.external-providers .col-4 {
  padding-left: 0;
  padding-right: 0;
}

div.update-survey-form div.external-providers .title {
  margin: 1rem 0;
  color: black;
  font-family: Effra Bold;
}

.update-survey-form__peer__providers {
  display: none;
}

.update-survey-form__peer__providers--visible {
  display: block;
}

.update-survey-form__peer__custom-providers .autocomplete-select-field-component {
  margin-bottom: 0;
}

.update-survey-form__peer__custom-providers .creatable-select-field {
  display: none;
}

.update-survey-form__peer__custom-providers--visible .creatable-select-field {
  display: block;
}

.update-survey-form__button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  margin: 18px 0;
  padding: 0;
  text-align: left;
  color: #838788;
}

.update-survey-form__button::before {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #e4212c;
  border-radius: 50%;
  margin-right: 8px;
  padding: 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  content: '+';
}

.update-survey-form__button--add-question {
  border: 1px solid #bbbbbb;
  padding: 10px;
  margin: 10px 0 20px 0;
  width: 100%;
}

.update-survey-form__button--add-peer {
  background: #e4212c;
  margin: 8px 0;
  padding: 8px;
  color: #ffffff;
}

.update-survey-form__button--add-peer::before {
  background: #ffffff;
  color: #e4212c;
}
