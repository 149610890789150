.top-bar #navbarDropdown {
  font-family: Effra Bold, Tahoma, Sans-Serif;
  cursor: pointer;
}

.top-bar .mobile-menu-button {
  cursor: pointer;
}

.top-bar li.reciving > span {
  color: #e4212c;
}

.top-bar li.giving > span {
  color: #f3c615;
}

.top-bar li.team > span {
  color: #63a9a4;
}
