@media print {
  .report-details * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .reports main.report-details {
    background: none;
  }

  .report-details .collapse:not(.show) {
    display: block;
  }

  .report-details .report-close {
    display: none;
  }

  .report-details .report-export {
    display: none;
  }

  .report-details .report-box {
    background: whitesmoke;
    border: none;
    box-shadow: none;
  }

  .report-details .card {
    border: none;
    box-shadow: none;
  }

  .report-details .show-comments {
    display: none;
  }

  #footer {
    display: none;
  }
}
